import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-feedback-filter',
  templateUrl: './feedback-filter.component.html',
  styleUrls: ['./feedback-filter.component.scss']
})
export class FeedbackFilterComponent implements OnInit {
  form!: FormGroup;
  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      intent: ['all'],
      replied: ['all'],
      showArchive: [false]
    });
    const feedbackfilter = localStorage.getItem('feedbackfilter');
    if(feedbackfilter !== null) {
      const filterValues = JSON.parse(feedbackfilter)
      this.form.patchValue({
        intent: filterValues.intent,
        replied: filterValues.replied,
        showArchive: filterValues.showArchive
      })
    }
  }

  onSubmit() {
    localStorage.setItem('feedbackfilter', JSON.stringify(this.form.value))
  }
}
