import { environment } from 'src/environments/environment';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';

import { MaterialModule } from 'material.module';
import { MatSelectModule } from '@angular/material/select';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { FeedbackDetailsComponent } from './components/feedback/feedback-details/feedback-details.component';
import { SendMessageComponent } from './components/chat/send-message/send-message.component';

import { FeedbackReplyComponent } from './components/feedback/feedback-reply/feedback-reply.component';
import { FeedbackDetailsDialogComponent } from './components/feedback/feedback-details-dialog/feedback-details-dialog.component';
import { ChatSocketService } from './services/socket/chat-socket.service';
import { LoginComponent } from './components/auth/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { FeedbackFilterComponent } from './components/feedback/feedback-filter/feedback-filter.component';
import { RoomsComponent } from './components/rooms/rooms.component';
import { ChatRoomMessagesComponent } from './components/chat-room-messages/chat-room-messages.component';
import { FeedbackListComponent } from './components/feedback/feedback-list/feedback-list.component';

// const socketIoOptions = {
// 	query: {
// 		userId: 'alerts',
// 		username: "InternetFM",
// 		token: "F9cJMDhWkv673RNmTkLyLLVsF3LL43RX",
// 	},
// }

// const socketIOConfig: SocketIoConfig = { url: environment.socket_url, options: socketIoOptions };




@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		FeedbackDetailsComponent,
		SendMessageComponent,
		FeedbackReplyComponent,
		FeedbackDetailsDialogComponent,
		LoginComponent,
  		FeedbackFilterComponent,
    	RoomsComponent,
     ChatRoomMessagesComponent,
     FeedbackListComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		MatSelectModule
		//SocketIoModule.forRoot(socketIOConfig),
	],
	providers: [DatePipe, ChatSocketService, AuthGuard],
	bootstrap: [AppComponent],
})
export class AppModule {}
