import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, map, catchError } from "rxjs/operators";

const AUTH_URL = environment.api_base_url;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient
  ) { }

  login(data:any): Observable<any>{
    return this.http.post<any>(`${AUTH_URL}/v1.0/feedback/auth/login`, data);
  }
  
  verifyToken(): Observable<boolean> {
    const token = localStorage.getItem('token');
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': `Bearer ${token}`
    });
    return token
      ? this.http.post(`${AUTH_URL}/v1.0/feedback/auth/validate`,'',{headers: headers }).pipe(
        tap(res => console.log('verifyToken',res)),
        map((res:any) => {
          localStorage.setItem('isHost', JSON.stringify(res.user.host))
          console.log('here verifyToken res', res)
          return true
        }),
        catchError(
          (error) => {
            return of(false);
          }
        )
      )
    : of(false);
  }

}
