import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ChatSocketService } from 'src/app/services/socket/chat-socket.service';

@Component({
  selector: 'app-chat-room-messages',
  templateUrl: './chat-room-messages.component.html',
  styleUrls: ['./chat-room-messages.component.scss']
})
export class ChatRoomMessagesComponent implements OnInit, OnDestroy {
  socketConnected: boolean = false;
	showLoading = true;
  roomId:any;
  displayedColumns: string[] = [
    'roomId',
    'type',
    'message',
    'timestamp',
    'userId',
    'username',
    'actions'
  ];
  data: MessagesTableRow[] = [];
  dataSource: MatTableDataSource<MessagesTableRow> = new MatTableDataSource(
    this.data
  );
  paginator!: MatPaginator;
  @ViewChild(MatPaginator,  {static: false}) set matPaginator(paginator: MatPaginator) {
    this.paginator = paginator;
 
    if (this.dataSource) {
      this.dataSource.paginator = paginator;
    }
  }
  constructor(
    private chatSocket: ChatSocketService,
    private route: ActivatedRoute
  ) { }

  ngOnDestroy() {
		this.chatSocket.disconnect();
	}

  ngOnInit(): void {
    this.roomId = this.route.snapshot.paramMap.get('roomId');
    this.setupConnection();
    this.getData();
  }

  setupConnection(){

		this.showLoading = true;

		this.chatSocket.setupConnection('alerts', 'InternetFM').subscribe({
			next: (conn) => {
				console.log("Connected to socket", conn)
				this.socketConnected = true;
			},
			error: (error) => {
				console.error('Socket did not connect', error);
				this.showLoading = false;
				this.socketConnected = false;
				this.chatSocket.disconnect();
			},
		});

		this.chatSocket.socketError();
	}

  getData() {
    this.chatSocket.getRoomMessages(this.roomId).then((res:any) =>{
      console.log('getRoomMessages', res.messages)
      this.data = res.messages;
      this.dataSource = new MatTableDataSource(res.messages); 
      this.dataSource.paginator = this.paginator;
    }).catch(err=>{

    }).finally(()=>{
      this.showLoading = false;
    });
  }

  onRetry() {
		this.setupConnection();
    this.getData();
	}

  onDelete(msg: any) {
    this.showLoading = true;
    this.chatSocket.deleteMessage(msg.id).then(()=>{
      this.showLoading = false;
    }).catch((err)=>{
      console.error("onDelete", err)
    })
  }

}

export interface MessagesTableRow {
  from: any;
  fromUser: any;
  message: string;
  roomId: string;
  timestamp: Date;
  toUser: null;
  type: string;
  userId: string;
  username: string;
}
