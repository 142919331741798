import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ChatSocketService } from 'src/app/services/socket/chat-socket.service';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss']
})

export class RoomsComponent implements OnInit, OnDestroy {
	socketConnected: boolean = false;
	showLoading = true;
  displayedColumns: string[] = [
    'name',
    'roomId',
    'order',
    'public',
    'actions',
  ];
  data: RoomsTableRow[] = [];
  dataSource: MatTableDataSource<RoomsTableRow> = new MatTableDataSource(
    this.data
  );
  paginator!: MatPaginator;
  @ViewChild(MatPaginator,  {static: false}) set matPaginator(paginator: MatPaginator) {
    this.paginator = paginator;
 
    if (this.dataSource) {
      this.dataSource.paginator = paginator;
    }
  }

	constructor(
    private chatSocket: ChatSocketService
  ) {}

	ngOnDestroy() {
		this.chatSocket.disconnect();
	}

	ngOnInit(): void {
		this.setupConnection();
    this.getData();
	}

  getData() {
    this.chatSocket.getAllRooms().then((res:any) =>{
      this.data = res;
      this.dataSource = new MatTableDataSource(res); 
      this.dataSource.paginator = this.paginator;
    }).catch(err=>{

    }).finally(()=>{
      this.showLoading = false;
    });
  }


	setupConnection(){

		this.showLoading = true;

		this.chatSocket.setupConnection('alerts', 'InternetFM').subscribe({
			next: (conn) => {
				console.log("Connected to socket", conn)
				this.socketConnected = true;
			},
			error: (error) => {
				console.error('Socket did not connect', error);
				this.showLoading = false;
				this.socketConnected = false;
				this.chatSocket.disconnect();
			},
		});

		this.chatSocket.socketError();
	}

  onRetry() {
		this.setupConnection();
    this.getData();
	}

}

export interface RoomsTableRow {
  name: string;
  order: string;
  public: boolean;
  roomId: string;
}