import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChatSocketService } from 'src/app/services/socket/chat-socket.service';
import {MatSnackBar} from '@angular/material/snack-bar'

@Component({
	selector: 'app-send-message',
	templateUrl: './send-message.component.html',
	styleUrls: ['./send-message.component.scss'],
})
export class SendMessageComponent implements OnInit, OnDestroy {
	messageText = new FormControl('');
	submitted = false;
	model: any;
	socketConnected: boolean = false;
	showLoading = true;
	constructor(private chatSocket: ChatSocketService, private snackBar: MatSnackBar) {}

	ngOnDestroy() {
		this.chatSocket.disconnect();
	}

	ngOnInit(): void {
		this.model = {
			messageText: '',
		};
		this.setupConnection();

	}

	onSubmit() {

		console.log('onSubmit', this.model);

		this.showLoading = true;
		
		this.chatSocket.sendAlert(this.model.messageText).then(ack=>{
			this.snackBar.open("Alert sent succesfully", undefined, {
				duration: 2000
			})
			this.model.messageText = "";
		}).catch(err=>{

		}).finally(()=>{
			this.showLoading = false;
		});
	}

	onRetry() {
		this.setupConnection();
	}

	setupConnection(){

		this.showLoading = true;

		this.chatSocket.setupConnection('alerts', 'InternetFM').subscribe({
			next: (conn) => {
				console.log("Connected to socket", conn)
				this.socketConnected = true;
				this.showLoading = false;
			},
			error: (error) => {
				console.error('Socket did not connect', error);
				this.showLoading = false;
				this.socketConnected = false;
				this.chatSocket.disconnect();
			},
		});

		this.chatSocket.socketError();
	}
}
