import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { io, Socket } from 'socket.io-client';
import { Subject } from 'rxjs';


class SocketConnection{
	connected:boolean = false;
	socket: any = null
}

class SocketError{
	message: string = ""
}


@Injectable({
	providedIn: 'root',
})

export class ChatSocketService {

	socket: any;
	
	socketConnection:SocketConnection = new SocketConnection()
	
	// the socket configuration is located in app.module.ts
	constructor() {}

	setupConnection(userId: string, username: string): Subject<SocketConnection>{

		let subject:Subject<SocketConnection> = new Subject();

		console.log('ChatSocketService | setupConnection');
		if (!this.socket) {

			// initialize the socket
			this.socket = io(environment.socket_url, {
				reconnectionDelayMax: 5000,
				query: {
					userId: userId,
					username: username,
					token: 'F9cJMDhWkv673RNmTkLyLLVsF3LL43RX',
					lastConnected: 0,
				},
			});

			// succesfull connection
			this.socket.on('connect', () => {
				console.log(`connected succesfully to ${environment.socket_url}`);
				this.socketConnection.connected = true;
				this.socketConnection.socket = this.socket;
				subject.next(this.socketConnection)
			});

			// failed connection
			this.socket.on("connect_error",   (err: any) => {
				subject.error(err);
			});

		}

		return subject
	}

	// disconnect from socket
	disconnect() {
		if (this.socket) {
			this.socket.disconnect();
			this.socket = undefined;
		}
	}
	
	// send message to all users (shown as alerts)
	sendAlert(msg: string) {

		return new Promise((resolve, reject)=>{
			const data = {
				message: msg,
				type: 'alert',
			};
	
			this.socket.emit('text_message', data, (res:any)=>{
				resolve(res);
			});
		})

	}

	// send message to all users (shown as alerts)
	alertUser(msg: string, userId: string) {
		const data = {
			message: msg,
			type: 'alert',
			toUser: userId,
		};

		this.socket.emit('text_message', data, (res: any) => {
			console.log('Acknowledged', res);
		});
	}

	// observe socket errors
	socketError(): Subject<SocketError> {

		const sub:Subject<SocketError> = new Subject();

		this.socket.on('error', (err: any) => {
			sub.next({ message: err.message})
		});

		return sub
	}

	// get rooms list
	getAllRooms() {
		this.socket.emit("list_all_rooms");
		return new Promise((resolve, reject)=>{
			this.socket.on("list_all_rooms", (res:any) => {
				resolve(res);
			});
		})
	}

	// get room Messages
	getRoomMessages(roomId:string) {
		this.socket.emit("sync_messages", 0, 'room', roomId);
		return new Promise((resolve, reject)=>{
			this.socket.on("sync_messages", (res:any) => {
				resolve(res);
			});
		})
	}

	// get rooms list
	deleteMessage(msgId: string) {
		this.socket.emit("text_message_deleted", msgId);
		return new Promise((resolve, reject)=>{
			this.socket.on("text_message_deleted", (res:any) => {
				resolve(res);
			});
		})
	}

}
