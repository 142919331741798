import { Component, OnInit, Inject, Input } from '@angular/core';
import { FeedbackService } from '../../../services/api/feedback.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeedbackItem } from 'src/app/models/FeedbackItem';
import { FeedbackTableRow } from '../../feedback/feedback-list/feedback-list.component';

@Component({
  selector: 'app-feedback-details',
  templateUrl: './feedback-details.component.html',
  styleUrls: ['./feedback-details.component.scss'],
})
export class FeedbackDetailsComponent implements OnInit {
  @Input() feedbackItem: FeedbackTableRow | undefined;
  form: FeedbackItem | undefined;
  constructor(private feedback: FeedbackService) {}

  ngOnInit(): void {
    console.log("FeedbackDetailsComponent | ngOnInit | this.form", this.feedbackItem)

    if (this.feedbackItem){
      this.feedback.getForm(this.feedbackItem.id).subscribe((result) => {
        console.log(`this.feedback.getForm(${result[0].id}) result: `,result);
        this.form = result[0];
      });
    }

  }
}
