import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loggedIn = false;
  isHost = false;
  constructor(){
    let login:any = localStorage.getItem('loggedIn');
    this.loggedIn = JSON.parse(login);
    let host:any = localStorage.getItem('isHost');
    this.isHost = JSON.parse(host);
  }
  title = 'InternetFM-web';
  contentMargin = 0;
  isOpened = true;
  toggleMenu(){
    console.log("toggleMenu");
    this.isOpened = !this.isOpened;

    if (this.isOpened){
      this.contentMargin = 0;
    } else {
      this.contentMargin = 60;
    }

  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('isHost');
  }


}
