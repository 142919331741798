import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { ChatRoomMessagesComponent } from './components/chat-room-messages/chat-room-messages.component';
import { SendMessageComponent } from './components/chat/send-message/send-message.component';
import { FeedbackListComponent } from './components/feedback/feedback-list/feedback-list.component';
import { HomeComponent } from './components/home/home.component';
import { RoomsComponent } from './components/rooms/rooms.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: HomeComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'feedback',
    component: FeedbackListComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'chat',
    component: SendMessageComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'chat/rooms',
    component: RoomsComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'chat/room/:roomId/messages',
    component: ChatRoomMessagesComponent,
    canActivate:[AuthGuard]
  },
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
