import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { map, ReplaySubject } from 'rxjs';
import { FeedbackService } from '../../../services/api/feedback.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { FeedbackReplyComponent } from '../feedback-reply/feedback-reply.component';
import { FeedbackDetailsDialogComponent } from '../feedback-details-dialog/feedback-details-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { FeedbackFilterComponent } from '../feedback-filter/feedback-filter.component';
import {MatIconRegistry} from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar'

@Component({
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['./feedback-list.component.scss'],
})

export class FeedbackListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'created_str',
    'station_code',
    'intent_label',
    'artist_label',
    'artist_value',
    'song_label',
    'song_value',
    'genre_label',
    'genre_value',
    'replied',
    'actions',
  ];
  data: FeedbackTableRow[] = [];
  dataSource: MatTableDataSource<FeedbackTableRow> = new MatTableDataSource(
    this.data
  );
  paginator?: MatPaginator;
  @ViewChild(MatPaginator,  {static: false}) set matPaginator(paginator: MatPaginator) {
    this.paginator = paginator;
 
    if (this.dataSource) {
        this.dataSource.paginator = paginator;
    }
 }
 subject$: ReplaySubject<FeedbackTableRow[]> = new ReplaySubject<FeedbackTableRow[]>(1);

  constructor(
    private feedback: FeedbackService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private iconReg: MatIconRegistry,
    private snackBar: MatSnackBar
  ) {}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(): void {
    localStorage.removeItem('feedbackfilter');
  }

  ngOnInit(): void {
    this.getData();
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  getData() {
    this.feedback.getAll().pipe(
      map((x, i) => {
        return x.map((y) => {
          return {
            id: y.id,
            created: y.created,
            created_str: this.datePipe.transform(y.created, 'short'),
            station_code: y.station_code,
            intent: y.intent,
            intent_label: y.intent == 1 ? "Positive": "Negtive",
            artist_label: y.metadata.artist,
            artist_value: y.details.artist,
            song_label: y.metadata.song,
            song_value: y.details.song,
            genre_label: y.metadata.genre,
            genre_value: y.details.genre,
            os_version: y.metadata.os_version,
            app_version:y.metadata.app_version,
            device_model: y.metadata.device_model,
            user_id: y.user_id,
            replied: y.replied,
            archived: false
          } as FeedbackTableRow;
        });
      })
    ).subscribe((result) => {
      console.log('this.feedback.getAll() result: ', result);
      const filteredresult = result.filter(function(x){
        return x.archived === false;
      });
      this.data = result;
      this.dataSource = new MatTableDataSource(filteredresult); 
    });
  }

  showDetails(id: number): void {
    console.log(`showing details of feedback ${id}`);
    const dialogRef = this.dialog.open(FeedbackDetailsDialogComponent, {
      data:  this.data.filter(el=> el.id == id)[0]
      , width: "80vw"
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  showReplyDialog(id:number){
    console.log(`showing reply for feedback ${id}`);
    const dialogRef = this.dialog.open(FeedbackReplyComponent, {
      data:  this.data.filter(el=> el.id == id)[0],
      width: "80vw"
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(result === true) {
        const index = this.data.findIndex((existingCustomer) => existingCustomer.id === id);
        this.data[index].replied = true;
        this.subject$.next(this.data);
      }
    });
  }
  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) {

    console.log("compare", a, b)
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortData(sort: Sort) {
    console.log('Running sortData', sort);
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      data.sort((a: FeedbackTableRow, b: FeedbackTableRow) => {
        return a.id > b.id ? 1 : -1;
      });
      this.dataSource = new MatTableDataSource(data);
      return;
    }

    this.data = data.sort((a: FeedbackTableRow, b: FeedbackTableRow) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'created':
          return this.compare(a.created, b.created, isAsc);
        case 'station_code':
          return this.compare(a.station_code, b.station_code, isAsc);
        case 'intent':
          return this.compare(a.intent, b.intent, isAsc);
        case 'artist_label':
          return this.compare(a.artist_label, b.artist_label, isAsc);
        case 'artist_value':
         return this.compare(a.artist_value.toString(), b.artist_value. toString(), isAsc);
        case 'song_label':
          return this.compare(a.song_label, b.song_label, isAsc);
        case 'song_value':
          return this.compare(a.song_value.toString(), b.song_value.toString(), isAsc);
        case 'genre_label':
          return this.compare(a.genre_label, b.genre_label, isAsc);
        case 'genre_value':
          return this.compare(a.genre_value.toString(),b.genre_value.toString(), isAsc);


        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.data);
  }

  filterPopup() {
    const dialogRef = this.dialog.open(FeedbackFilterComponent, {
      width: "30vw"
    });
    dialogRef.afterClosed().subscribe((result) => {
      const filters:any = {}; let filteredresult:any = [];
      if(result.intent !== undefined) {
        result.intent = result.intent == '0' ? 0 : result.intent == '1' ? 1 : '',
        result.replied = result.replied == 'true' ? true : result.replied == 'false' ? false : '';
        if(result.intent !== '')
          filters.intent = result.intent;
        if(result.replied !== '')
          filters.replied = result.replied;
        if(result.showArchive === false) {
          filteredresult = this.data.filter(function(x:any){
            return x.archived === false;
          });
        } else {
          filteredresult = this.data;
        }
        const filteredData = filteredresult.filter(function(item:any) {
          for (var key in filters) {
            if (item[key] === undefined || item[key] != filters[key])
              return false;
          }
          return true;
        });
        this.dataSource = new MatTableDataSource<any>(filteredData);
      }
    });
  }

  msgArchived(id:number) {
    this.feedback.archiveFeedbackMessage(id,{archived: true}).subscribe((res) => {
      this.getData();
      this.snackBar.open("Message Archived Succesfully!", undefined, {
				duration: 2000
			});
    })
  }
}

export interface FeedbackTableRow {
  id: number;
  created: Date;
  created_str: string;
  station_code: string;
  intent: number;
  intent_label: string
  artist_label: string;
  artist_value: boolean;
  song_label: string;
  song_value: boolean;
  genre_label: string;
  genre_value: boolean;
  os_version: string;
  app_version: string;
  device_model: string;
  user_id: string;
  replied: boolean;
  archived: boolean;
}
