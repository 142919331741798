<div class="room-container">
    <!-- Error alert-->
    <div *ngIf="!socketConnected && !showLoading">
        <div class="alert alert-danger" role="alert">Could not connect to socket! <button class="btn btn-success" (click)="onRetry()">try again</button></div>
    </div>
    <section *ngIf="socketConnected">
      <table mat-table matSort class="mt-1" [dataSource]="dataSource">
  
        <!-- Room ID Column -->
        <ng-container matColumnDef="roomId" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header="roomId">Room ID</th>
          <td mat-cell *matCellDef="let element">{{ element.roomId }}</td>
        </ng-container>
  
        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="type">Type</th>
          <td mat-cell *matCellDef="let element">{{ element.type }}</td>
        </ng-container>
  
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header="name">Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
  
        <!-- Message Column -->
        <ng-container matColumnDef="message" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header="message">Message</th>
          <td mat-cell *matCellDef="let element">{{ element.message }}</td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="timestamp" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header="timestamp">Date</th>
            <td mat-cell *matCellDef="let element">{{ element.timestamp | date }}</td>
        </ng-container>

        <!-- User Id Column -->
        <ng-container matColumnDef="userId" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header="userId">User Id</th>
            <td mat-cell *matCellDef="let element">{{ element.message }}</td>
        </ng-container>

        <!-- Username Column -->
        <ng-container matColumnDef="username" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header="username">Username</th>
            <td mat-cell *matCellDef="let element">{{ element.username }}</td>
        </ng-container>
  
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <div class="action-buttons">
              <button mat-button mat-stroked-button (click)="onDelete(element)">Delete</button>
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons aria-label="rooms-table">
      </mat-paginator>
    </section>
</div>
  
<mat-progress-spinner *ngIf="showLoading" class="spinner" color="primary" mode="indeterminate"> </mat-progress-spinner>
  