import { Component, OnInit, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeedbackItem } from 'src/app/models/FeedbackItem';
import { FeedbackTableRow } from '../../feedback/feedback-list/feedback-list.component';

@Component({
  selector: 'app-feedback-details-dialog',
  templateUrl: './feedback-details-dialog.component.html',
  styleUrls: ['./feedback-details-dialog.component.scss'],
})
export class FeedbackDetailsDialogComponent implements OnInit {
  feedbackItem: FeedbackTableRow | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FeedbackTableRow
  ) {}

  ngOnInit(): void {

    console.log("FeedbackDetailsDialogComponent | ngOnInit | this.data ", this.data)
    if (this.data)
      this.feedbackItem = this.data;
  }
}
