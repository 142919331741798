<h2 mat-dialog-title>Filter by</h2>

<mat-dialog-content class="mat-typography">
    <form [formGroup]="form">
        <div class="form-group my-3 row">
            <h2 class="filter-label col-3">Intent</h2>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Intent</mat-label>
                <mat-select formControlName="intent">
                  <mat-option value="all">All</mat-option>
                  <mat-option value=1>Positive</mat-option>
                  <mat-option value=0>Negative</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-group my-3">
            <h2 class="filter-label col-3">Replied</h2>
            <mat-form-field appearance="fill" class="col-6">
                <mat-label>Replied</mat-label>
                <mat-select formControlName="replied">
                  <mat-option value="all">All</mat-option>
                  <mat-option value=true>True</mat-option>
                  <mat-option value=false>False</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="form-group my-3">
            <h2 class="filter-label col-3">Show Archive</h2>
            <mat-checkbox  color="primary" formControlName="showArchive">
            </mat-checkbox>
        </div>
    </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button type="button" mat-button color="primary" [mat-dialog-close]="form.value" cdkFocusInitial (click)="onSubmit()">Submit</button>
</mat-dialog-actions>
