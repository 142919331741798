

<h2 mat-dialog-title>Feedback Details</h2>

<mat-dialog-content class="mat-typography">

    <div class="feedback-details" *ngIf="form">
        <div class="form-group">

            <div class="header">Intent: {{form.intent == 1 ? "Positive": "Negtive" }}</div>
            <div class="header">UserId: {{form.user_id }}</div>
            <div class="header">Replied: {{form.replied}}</div>

            <div class="header">Details</div>
            <div class="element">
                <div class="label">Artist:&nbsp;</div>
                <div class="value">{{form.details.artist}}</div>
                <div class="clear"></div>
            </div>
            <div class="element">
                <div class="label">Song:&nbsp;</div>
                <div class="value">{{form.details.song}}</div>
                <div class="clear"></div>
            </div>
            <div class="element">
                <div class="label">Genre:&nbsp;</div>
                <div class="value">{{form.details.genre}}</div>
                <div class="clear"></div>
            </div>
            <div class="element">
                <div class="label">Comments:&nbsp;</div>
                <div class="value">{{form.details.comments}}</div>
                <div class="clear"></div>
            </div>
        </div>

        <div class="form-group">
            <div class="header">Metadata</div>
            <div class="element">
                <div class="label">Artist:&nbsp;</div>
                <div class="value">{{form.metadata.artist}}</div>
                <div class="clear"></div>
            </div>
            <div class="element">
                <div class="label">Song:&nbsp;</div>
                <div class="value">{{form.metadata.song}}</div>
                <div class="clear"></div>
            </div>
            <div class="element">
                <div class="label">Genre:&nbsp;</div>
                <div class="value">{{form.metadata.genre}}</div>
                <div class="clear"></div>
            </div>
        </div>
    
    </div>
</mat-dialog-content>
