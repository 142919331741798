import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeedbackItem } from 'src/app/models/FeedbackItem';
import { FeedbackService } from 'src/app/services/api/feedback.service';
import { ChatSocketService } from 'src/app/services/socket/chat-socket.service';
import { FeedbackTableRow } from '../../feedback/feedback-list/feedback-list.component';

@Component({
	selector: 'app-feedback-reply',
	templateUrl: './feedback-reply.component.html',
	styleUrls: ['./feedback-reply.component.scss'],
})
export class FeedbackReplyComponent implements OnInit, OnDestroy {
	feedbackItem: FeedbackTableRow | undefined;
	model: any;
	messageText = new FormControl('');

	constructor(@Inject(MAT_DIALOG_DATA) public data: FeedbackTableRow, private chatSocket: ChatSocketService, private feedback: FeedbackService,) {}
	ngOnDestroy() {
		this.chatSocket.disconnect();
	  }

	ngOnInit(): void {
		console.log('FeedbackReplyComponent | ngOnInit | this.data ', this.data);

		this.model = {
			messageText: '',
		};

		if (this.data) {
			this.feedbackItem = this.data;
		}

		this.chatSocket.setupConnection("AFR", "Acid Flashback");

		this.chatSocket.socketError();
	}

	onSubmit() {
		console.log('onSubmit', this.model), this.feedbackItem;

		if (this.feedbackItem) {

			// emit reply to user
			let content = `Feedbck Response: ${this.feedbackItem?.artist_label} - ${this.feedbackItem?.song_label}\r\n${this.model.messageText}`;
			this.chatSocket.alertUser(content, this.feedbackItem.user_id);

			// mark feedback item as replied.
			const id:any = this.feedbackItem?.id;
			this.feedback.alterFeedbackReplied(id,{replied: true}).subscribe((res) => {
				console.log('alterFeedbackReplied', res);
			})

		}



	}
}
