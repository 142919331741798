<mat-drawer-container>
  <mat-drawer #drawer mode="side" disableClose="true" [opened]="isOpened" *ngIf="loggedIn">
    <!-- <button (click)="toggleMenu()" mat-mini-fab color="primary" style="margin: 10px;">
    <mat-icon aria-label="Menu">menu</mat-icon>
    </button> -->

    <section>

      <div class="logo">
          <img src="assets/internetfm_logo.png" >
      </div>
    </section>
            
    <div class="menu">
      <div class="menu-item"><a [routerLink]="['']">Home</a></div>
      <div class="menu-item"><a [routerLink]="['feedback']">Feedback</a></div>
      <div class="menu-item" *ngIf="isHost"><a [routerLink]="['chat']">Alerts</a></div>
      <div class="menu-item" *ngIf="isHost"><a [routerLink]="['chat/rooms']">Rooms</a></div>
      <div class="menu-item"><a href="/login" (click)="logout()">Logout</a></div>
    </div>
  </mat-drawer>

  <div class="content">
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
