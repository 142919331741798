<div class="feedback-list-container">


    <section>
      <div class="action-buttons">
        <!-- <button mat-stroked-button>Basic</button>
      <button mat-stroked-button color="primary">Primary</button> -->
        <!-- <button mat-stroked-button disabled>Archive</button> -->
  
        <!-- <button mat-stroked-button color="accent">Accent</button>
      <button mat-stroked-button color="warn">Warn</button>
      <button mat-stroked-button disabled>Disabled</button>
      <a mat-stroked-button href="https://www.google.com/" target="_blank">Link</a> -->
        <!-- <mat-divider></mat-divider> -->
      </div>
    </section>
    <!-- <section>
      <mat-divider></mat-divider>
    </section> -->
    <section>
      <mat-form-field appearance="standard">
        <mat-label>Filter</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Filter"
          #input
        />
        <button mat-icon-button matSuffix aria-label="filter" (click)="filterPopup()">
          <!-- <img src="assets/icons/filter-icon.png" alt="filter-icon" /> -->
          <mat-icon aria-hidden="false" aria-label="filter icon" >tune</mat-icon>
        </button>
      </mat-form-field>
    </section>
    <section>
      <table mat-table matSort [dataSource]="dataSource" (matSortChange)="sortData($event)" class="feedback-table">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
  
        <!-- Created Column -->
        <ng-container matColumnDef="created_str" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header="created">Created</th>
          <td mat-cell *matCellDef="let element">{{ element.created_str }}</td>
        </ng-container>
  
        <!-- Station Code Column -->
        <ng-container matColumnDef="station_code">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="station_code">Code</th>
          <td mat-cell *matCellDef="let element">{{ element.station_code }}</td>
        </ng-container>
  
        <!-- Intent Column -->
        <ng-container matColumnDef="intent_label">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header="intent">Intent</th>
          <td mat-cell *matCellDef="let element">{{ element.intent_label }}</td>
        </ng-container>
  
        <!-- artist_label Column -->
        <ng-container matColumnDef="artist_label" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header="artist_label">Artist</th>
          <td mat-cell *matCellDef="let element">{{ element.artist_label }}</td>
        </ng-container>
  
        <!-- artist_value Column -->
        <ng-container matColumnDef="artist_value" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header="artist_value">Liked?</th>
          <td mat-cell *matCellDef="let element">{{ element.artist_value }}</td>
        </ng-container>
  
        <!-- song_label Column -->
        <ng-container matColumnDef="song_label" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header="song_label">Song</th>
          <td mat-cell *matCellDef="let element">{{ (element.song_label.length>50)? (element.song_label | slice:0:50)+'..':(element.song_label) }}</td>
        </ng-container>
  
        <!-- song_value Column -->
        <ng-container matColumnDef="song_value" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header="song_value">Liked?</th>
          <td mat-cell *matCellDef="let element">{{ element.song_value }}</td>
        </ng-container>
  
        <!-- genre_label Column -->
        <ng-container matColumnDef="genre_label">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="genre_label">Genre</th>
          <td mat-cell *matCellDef="let element">{{ element.genre_label }}</td>
        </ng-container>
  
        <!-- genre_value Column -->
        <ng-container matColumnDef="genre_value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="genre_value">Liked?</th>
          <td mat-cell *matCellDef="let element">{{ element.genre_value }}</td>
        </ng-container>
  
        <!-- genre_value Column -->
        <ng-container matColumnDef="user_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="user_id">User</th>
          <td mat-cell *matCellDef="let element">{{ element.user_id }}</td>
        </ng-container>
  
        <!-- replied Column -->
        <ng-container matColumnDef="replied">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="replied">Replied?</th>
          <td mat-cell *matCellDef="let element">{{ element.replied }}</td>
        </ng-container>
  
        <!-- genre_value Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <div class="action-buttons">
              <button mat-button mat-stroked-button (click)="showDetails(element.id)">Details</button>
              <button mat-button mat-stroked-button (click)="msgArchived(element.id)">Archive</button>
              <button *ngIf="element.user_id!=''" mat-button mat-stroked-button (click)="showReplyDialog(element.id)">Reply</button>
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="feedback-table">
      </mat-paginator>
    </section>
    <router-outlet></router-outlet>
  </div>
  