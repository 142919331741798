<div class="room-container">
  <!-- Error alert-->
	<div *ngIf="!socketConnected && !showLoading">
		<div class="alert alert-danger" role="alert">Could not connect to socket! <button class="btn btn-success" (click)="onRetry()">try again</button></div>
	</div>
  <section *ngIf="socketConnected">
    <table mat-table matSort class="mt-1" [dataSource]="dataSource">

      <!-- Room ID Column -->
      <ng-container matColumnDef="roomId" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header="roomId">Room ID</th>
        <td mat-cell *matCellDef="let element">{{ element.roomId }}</td>
      </ng-container>

      <!-- Order Column -->
      <ng-container matColumnDef="order">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="order">Order</th>
        <td mat-cell *matCellDef="let element">{{ element.order }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header="name">Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- Public Column -->
      <ng-container matColumnDef="public" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header="public">Public?</th>
        <td mat-cell *matCellDef="let element">{{ element.public }}</td>
      </ng-container>

      <!-- genre_value Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <div class="action-buttons">
            <button mat-button mat-stroked-button [routerLink]="['/chat/room', element.roomId, 'messages']">Messages</button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="rooms-table">
    </mat-paginator>
  </section>
</div>

<mat-progress-spinner *ngIf="showLoading" class="spinner" color="primary" mode="indeterminate"> </mat-progress-spinner>
