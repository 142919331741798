import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService} from '../services/api/auth.service'
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      console.log('******canActivate AuthGuard*******')
      return this.auth.verifyToken().pipe(
        tap(allowed => {
          localStorage.setItem('loggedIn', JSON.stringify(allowed))
          if (!allowed) this.router.navigate(["/login"]);
        })
      );
    }
}
