import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FeedbackItem } from 'src/app/models/FeedbackItem';
import { map, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  private baseUrl = environment.api_base_url;
  private token ='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJ0ZXN0QGV4YW1wbGUuY29tIiwiaWF0IjoxNjMzNjQ4NDYzLCJleHAiOjE2ODA5OTU2NjN9.FUtXnhRa2PxU7fciiuDY38KC0QgqYGT7-l66JRzrYvM';

  constructor(private http: HttpClient) {}

  getAll(): Observable<FeedbackItem[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token,
      }),
    };
    return this.http.get(
      `${this.baseUrl}/v1.0/feedback`,
      httpOptions
    ) as Observable<FeedbackItem[]>;
  }

  getForm(id:number): Observable<FeedbackItem[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token,
      }),
    };
    return this.http.get(
      `${this.baseUrl}/v1.0/feedback/form/${id}`,
      httpOptions
    ) as Observable<FeedbackItem[]>;
  }

  getByStation(code:string): Observable<FeedbackItem[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token,
      }),
    };
    return this.http.get(
      `${this.baseUrl}/v1.0/feedback/form/${code}`,
      httpOptions
    ) as Observable<FeedbackItem[]>;
  }

  alterFeedbackReplied(id:number, data:any): Observable<FeedbackItem[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token,
      }),
    };
    return this.http.post(
      `${this.baseUrl}/v1.0/feedback/form/alterFeedbackReplied/${id}`, data,
      httpOptions
    ) as Observable<FeedbackItem[]>;
  }

  archiveFeedbackMessage(id:number, data:any): Observable<FeedbackItem[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.token,
      }),
    };
    return this.http.post(
      `${this.baseUrl}/v1.0/feedback/form/archiveFeedbackMessage/${id}`, data,
      httpOptions
    ) as Observable<FeedbackItem[]>;
  }

}
