import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/api/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form!: FormGroup;
  loading = false;
  errorText:any;
  constructor(
    private fb: FormBuilder,
    private authSvc: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.form.valueChanges.subscribe( () => {
      this.errorText = '';
    });
  }

  onSubmit() {
    this.loading = true;
    const data = this.form.value;
    this.authSvc.login(data).subscribe({
      next: (res) => {
        localStorage.setItem('token', res.user.auth_token);
        localStorage.setItem('isHost', JSON.stringify(res.user.host))
        localStorage.setItem('loggedIn', JSON.stringify(true));
        this.loading = false;
        window.location.href = '/';
      },
      error: (error)  => {
        this.loading = false;
        this.errorText = error.error
        console.log('authSvc.login error', error)

      }
    })
  }

}
