
<app-feedback-details [feedbackItem]="feedbackItem"></app-feedback-details>

<div>
    <form (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="form-group">
            <label for="name">Reply Text</label>
            <textarea class="form-control" id="messageText" required [(ngModel)]="model.messageText" name="messageText" #name="ngModel" ></textarea>
            <div [hidden]="messageText.valid" class="alert alert-danger">Name is required</div>
        </div>
    
        <button type="submit" class="btn btn-success" mat-dialog-close [disabled]="!form.form.valid" [mat-dialog-close]="true">Submit</button>
    
    </form>
    
</div>
