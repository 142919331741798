<div class="back">

    <div class="div-center">
  
      <div class="content">
  
        <h2 class="text-center">Member Login</h2>
        <form [formGroup]="form" (submit)="onSubmit()">
          <div class="form-group my-3 input-icons">
            <i class="fa fa-user icon fa-lg"></i>
            <input formControlName="email" type="email" class="form-control input-field" placeholder="Username" >
          </div>
          <div class="form-group my-2 input-icons">
            <i class="fa fa-lock icon fa-lg"></i>
            <input formControlName="password" type="password" class="form-control input-field" placeholder="Password">
          </div>
          <div class="form-group my-3">
              <button type="submit" class="light-green btn btn-success btn-lg w-100">Login
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
              </button>
          </div>
          <div>
              <p class="text-danger">{{errorText}}</p>
          </div>
        </form>

      </div>

    </div>
</div>