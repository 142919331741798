<div class="content-component">

    <!-- Error alert-->
	<div *ngIf="!socketConnected">
		<div class="alert alert-danger" role="alert">Could not connect to socket! <button class="btn btn-success" (click)="onRetry()">try again</button></div>
	</div>

	<h2>Send Public Message</h2>
	<form (ngSubmit)="onSubmit()" #form="ngForm">
		<div class="form-group">
			<label for="name">Message Text</label>
			<input
				type="text"
				class="form-control"
				id="messageText"
				required
				[(ngModel)]="model.messageText"
				name="messageText"
				#name="ngModel"
			/>
			<div [hidden]="messageText.valid" class="alert alert-danger">Name is required</div>
		</div>

		<button type="submit" class="btn btn-success" [disabled]="!form.form.valid && !showLoading">Submit</button>
	</form>
</div>

<mat-progress-spinner *ngIf="showLoading" class="spinner" color="primary" mode="indeterminate"> </mat-progress-spinner>
